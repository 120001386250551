/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ticket-lite-card::v-deep {
  width: 100%;
}
.ticket-lite-card::v-deep .ant-card-body {
  padding: 12px 0 12px 12px;
}
.ticket-lite-card::v-deep .mycard-content {
  position: relative;
}
.ticket-lite-card::v-deep .mycard-content .ticket-content {
  flex-grow: 1;
  padding-right: 12px;
  border-right: 1px solid #d9d9d9;
}
.ticket-lite-card::v-deep .mycard-content .mycard-side-actions {
  min-width: 80px;
  text-align: center;
}
.ticket-lite-card::v-deep .mycard-content .mycard-side-actions.actived {
  background-color: #d9f7be;
  align-self: stretch;
  padding-top: 8px;
}
.header-row {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
}
.topic-row {
  margin: 6px 0 8px;
  font-size: 0.95em;
}
.text-detail {
  color: #8c8c8c;
  font-size: 0.8em;
  vertical-align: middle;
}
.primary-color-text {
  color: #14837B;
}
