/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.error-message-pane {
  display: flex;
  flex-wrap: nowrap;
  padding: 16px;
  background-color: #fff1f0;
  margin-bottom: 16px;
  border: 1px solid #f5222d;
  border-radius: 4px;
  position: relative;
}
.mobile .error-message-pane {
  font-size: 0.9em;
}
.error-message-pane .message-icon {
  font-size: 2em;
  color: #f5222d;
  padding-right: 12px;
  vertical-align: top;
  margin-top: -4px;
}
.error-message-pane .message-content {
  flex-grow: 1;
  position: relative;
}
.error-message-pane .message-content .title {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-size: 1.2em;
  color: #f5222d;
}
.error-message-pane .message-content .description {
  margin-top: 4px;
}
.error-message-pane .message-content.no-detail {
  margin-top: 4px;
}
.mobile .error-message-pane .message-content.no-detail {
  margin-top: 2px;
}
.error-message-pane .message-content .debug {
  position: absolute;
  bottom: -12px;
  text-align: right;
  right: 0;
  color: #8c8c8c;
  font-size: 0.8em;
}
.error-message-pane .model-description {
  list-style: none;
  margin-left: 12px;
}
.error-message-pane .model-description:last-child {
  margin-bottom: 0;
}
.error-message-pane .model-description .model-detail-title {
  text-decoration: underline;
}
.error-message-pane .model-description .model-details {
  margin-left: 32px;
  font-size: 0.9em;
}
