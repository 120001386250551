/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.link-status-icon {
  font-size: 1.5em;
  margin-bottom: 8px;
  display: block;
}
.ticket-list {
  min-height: 300px;
}
.ticket-description {
  text-align: right;
  font-size: 0.9em;
}
.main-header {
  text-align: center;
}
.sub-header {
  font-weight: 550;
  color: #14837b;
  background-color: #fefefe;
  padding: 5px;
  margin-left: 0;
  margin-bottom: 10px;
}
.page-action-right {
  float: right;
  margin-bottom: 8px;
}
